import moment from "moment";
export const articles = [
    {
        'publish_date': moment().subtract(7, 'days'),
        'title': 'Newly Found Letter From Founder Domizio Moretti',
        'link': 'newly-found-letter-domizio-moretti',
        'image_url': 'https://dropschema.s3.amazonaws.com/oldcouches/domizio.png',
        'content': `
<img src="https://dropschema.s3.amazonaws.com/oldcouches/domizio.png" style="width:100%" alt="domizio"/>

<br/>
<br/>

To this day, Domizio Morretti's design philosophy is infused in every item we bring to life. It was Domizio’s dream to bring the luxury and elegance of Old Couches, or Vecchi Divani, in his native Italian, to the global stage, where anyone with the means and desire necessary, could sit on an old couch.

<br/>
It was Domizio’s endless pursuit for what he considered the perfect piece of furniture (in his words: "*The perfect mix of tattered, fading, and ravaged by the elements*"), that made him such a great designer. In this newly found letter written to Domizio’s assistant, Guisseppe, in 1908, we find Domizio’s ideals of beauty and elegance laid bare in stunning clarity.
<br/>
<br/>

>Guisseppe, my dear boy, in all my life I have never been so inspired. 
>
>During summer holiday in the Arbia valley near Siena and on one of our many walks, we encountered the most wonderful derelict cabin. An old house full of squatters, vagrants and other such ilk of poor means and education. “Ho there, my good transient!” I called out to them. “If you are not too occupied with sores or bed bug bites, might you show me the road to Merse?” They stared at me blankly. One squatter, his feet propped up on the railing, spat a wad of tobacco over the same railing and onto the front yard. The spit, a large, black, globulous beauty, arced through the air, and fell squarely on top of a couch sitting in their yard. Clearly an act of contempt towards me, this beautiful discharge of saliva, in actuality left me eternally grateful to its creator. For without him, I may have never noticed this unlikely piece of outdoor furniture. 

>It wasn’t just any couch, but the most beautiful and begrimed couch I have ever seen. Its midsection was split in half. Tufts of cotton, matted by rain and grime, was spilling out of its wound. A beautiful spattering of hundreds of wads of tobacco spit traveling in the same direction gathered on one of the cushions. I ran to the couch and sank to my knees. I took in every rip and every frayed fiber, careful not to touch the couch. 

>“Do you work for Severino? Where did you learn your craft?” I hastily asked them. “Whoever pays you, I will pay double”. They were taken aback by my many questions, but my enthusiasm eventually warmed them to my consistent prodding.

>This old, dilapidated couch is not quite it. But it is close to the ideal we have been searching for. Perhaps, we need to experiment more with weathering our couches in rainy climate and using vagrants to supply some of the wear and tear.

>Guiseppe, please prepare several workbenches in our northeast wing. We will have several new designers joining us shortly.

>Un Saluto, 

>Domizio Moretti
`
    },
    {
        'publish_date': moment().subtract(37, 'days'),
        'title': 'Collection: Old Couches X Natoshi Shigumura',
        'link': 'old-couches-x-natoshi-shigumura',
        'image_url': 'https://dropschema.s3.amazonaws.com/oldcouches/items/goto_1.jpg',
        'content': `
<br/>
<img  style="margin-left: auto;margin-right: auto;width: 50%;display: block" alt="goto1" src="https://dropschema.s3.amazonaws.com/oldcouches/items/goto_1.jpg"/>

<br/>

*Written by Natoshi Shigumura lead designer, Ryuji Sato.*


In Japan, we have the concept of Chūmon, which translates to *everything in it’s proper place*. This is a core concept in the Japanese designing tradition that Natoshi Shigumura eschews. At our agency, we adhere to the concept of Muchitsujo, which roughly translates to disorder, or entropy.

 Perhaps our founder, Mr. Shigumura, said it best. 
<br/>
>I adhere to one ideal, one concept of beauty, and that is the beauty of total destruction. It is in the flames of destruction, the complete annihilation of our species at the hand of nature and the passage of time that we shall find true beauty. I wish to accelerate this annihilation. This is not a metaphor for the dissolution of self or the ego, but rather a statement that should be interpreted literally. I desire to see our species and all of its creations disappear. 

We no longer carry such an extreme design philosophy. But this passage from Mr. Shigumura demonstrates some of the beliefs we still hold dear. The passage of time is our greatest tool. We do not design for human usability or comfort. Our products are just at home in an abandoned field as they are in a disaster area.

Because of our many shared beliefs, Natoshi Shigumura is excited to pair with Old Couches to produce this once-in-a-lifetime collection. With our combined expertise in old and abandoned products, we hope to bring you a consumer experience you will never forget.`
    }


]