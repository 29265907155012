import React, {useState} from "react";
import {Row, Col, Card, List, Divider, Button} from "antd";
import {Link} from "react-router-dom";
import Text from "antd/es/typography/Text";
import {articles} from "./articles";
import ReactMarkdown from "react-markdown";
import {useMediaQuery} from "react-responsive";

const {Meta} = Card;

export const MagazineListItem = ({item}) => {
    const isTabletOrMobile = useMediaQuery({query: '(max-width: 1224px)'})

    return (
        <>
            <Divider/>
            <Link to={'/magazine/' + item.link}>
                <List.Item>
                    <List.Item.Meta
                        style={{alignItems: 'center'}}
                        avatar={<img style={{height: '100px'}} alt={item.image_url.split('/')[-1]}
                                     src={item.image_url}/>}
                        title={<Text
                            style={{
                                fontSize: isTabletOrMobile ? '16px' : '40px',
                                fontFamily: 'Louis Vuitton'
                            }}>{item.title}</Text>}
                        description={<Text>{item.publish_date.format('MMMM Do YYYY')}</Text>}
                    />
                </List.Item>
            </Link>
            <Divider/>
        </>
    )
}

const MagazinePage = () => {
    const isTabletOrMobile = useMediaQuery({query: '(max-width: 1224px)'})
    return (
        <>

            <Row justify={'center'}>
                <Col lg={16} xl={16} md={20} xs={23} sm={23}>
                    <List
                        // bordered
                        itemLayout="horizontal"
                        dataSource={articles}
                        renderItem={item => (
                            <MagazineListItem item={item}/>
                        )}
                    />
                </Col>
            </Row>
            <Row justify={'center'}>
                <Card
                    // cover={<img alt="goto" src={ "https://dropschema.s3.amazonaws.com/oldcouches/items/yama_1.jpg"}/>}
                    style={{textAlign: 'center'}}
                >
                    {/*<Text>*/}
                    {/*    Experience some of our New Collections*/}
                    {/*</Text>*/}

                    <Meta style={{textAlign: 'center', alignItems: 'center'}} title={<Text strong style={{
                        fontFamily: 'Louis Vuitton',
                        fontSize: isTabletOrMobile ? '2vw' : '1.4vw'
                    }}>
                        Find Items From One of Our Newest Collections</Text>}/>
                    <br/>
                    <Link to={'/catalogue'} style={{textAlign: 'center', alignItems: 'center'}}>
                        <Button size='large' type={'primary'} shape={'round'}>
                            Shop Now
                        </Button>
                    </Link>
                </Card>

            </Row>
        </>
    )
}

export const ArticlePage = ({match}) => {
    const {params: {articleLink}} = match;
    const [article] = useState(articles.filter(x => x.link === articleLink)[0])

    const renderers = {
        blockquote: ({children}) => {
            // console.log(value)
            return <blockquote
                style={{backgroundColor: 'rgb(234 228 228)', padding: '20px 10px 10px 20px', borderRadius: '10px'}}>
                {children}</blockquote>
        }

    };

    return (
        <Row justify={'center'}
             // align={'middle'}
        >
            <Col
                xl={{span: 2}} lg={{span: 2}} sm={{span: 2}}
                xs={{span: 2}} md={{span: 2}}
            >
                <Link to={'/magazine'} style={{margin: '50px 0px 0px 0px'}}>
                    <Button style={{margin: '50px 10px'}}>Read More Articles</Button>
                </Link>

            </Col>
            <Col xl={{span: 12, offset: 1}} lg={{span: 12, offset: 1}} sm={{span: 20, offset: 1}}
                 xs={{span: 23, offset: 1}} md={{span: 23, offset: 1}}
                 style={{fontSize: '20px', fontFamily: 'Louis Vuitton'}}>
                <Card title={<Text style={{fontSize: '30px'}}>{article.title}</Text>}
                      style={{fontSize: '20px', alignItems: 'center'}}>
                    <Text>Published {article.publish_date.format('MMMM Do YYYY')}</Text>
                    <ReactMarkdown skipHtml={false} source={article.content} escapeHtml={false}
                                   renderers={renderers}/>
                </Card>
            </Col>
        </Row>
    )
}


export default MagazinePage;