import React from 'react';
import {Link} from 'react-router-dom';
import {Layout, Menu, Col, Row} from 'antd'
import * as ROUTES from '../contants/routes';
import {MenuOutlined} from '@ant-design/icons';
import Text from "antd/es/typography/Text";
import Title from "antd/es/typography/Title";

const {Header} = Layout;

const Navigation = ({header_style}) => (

    <Header style={{...header_style}}>
        <Row>
            <Col
                xs={{span: 10, offset: 2}} sm={{span: 10, offset: 2}} md={{span: 8, offset: 4}}
                lg={{span: 5, offset: 4}} xl={{span: 5, offset: 4}}
            >
                <Link to={ROUTES.HOME}><img style={{ height: '75%', maxWidth: '100%'}} alt={"logo"}
                                            src={"https://dropschema.s3.amazonaws.com/oldcouches/logo_with_couch_and_name_LV.svg"}/>
                </Link>
            </Col>

            <Col
                xs={{span: 10, offset: 0}} sm={{span: 6, offset: 0}} md={{span: 8, offset: 2}}
                lg={{span: 8, offset: 2}} xl={{span: 8, offset: 2}}
            >

                <Menu overflowedIndicator={<MenuOutlined/>} mode="horizontal"
                      style={{float: 'center', background: '0 0', borderBottom: '0'}}
                >
                    <Menu.Item style={{ marginTop: '10px', height: '55px'}} key="catalogue">
                        <Link to={ROUTES.CATALOGUE}><Text style={{fontSize: '16px', fontFamily: 'Louis Vuitton'}} >CATALOGUE</Text></Link>
                    </Menu.Item>
                    <Menu.Item style={{ marginTop: '10px', height: '55px'}} key="magazine">
                        <Link to={ROUTES.MAGAZINE}><Text style={{fontSize: '16px', fontFamily: 'Louis Vuitton'}} >MAGAZINE</Text></Link>
                    </Menu.Item>
                </Menu>

            </Col>
        </Row>
    </Header>
);

export default Navigation;