import React from "react";
import {Row, Col, Card, Button, Space} from "antd";
import Text from "antd/es/typography/Text";
import Title from "antd/es/typography/Title";
import {useMediaQuery} from 'react-responsive';
import {Link} from "react-router-dom";
import {articles} from "../Magazine/articles";
import {MagazineListItem} from '../Magazine'

const {Meta} = Card;

const Banner = () => {
        return (

            <div style={{
                // backgroundImage: 'url(https://gw.alipayobjects.com/zos/basement_prod/a8cf2dfe-ff6a-4a2e-ab73-f72dced0df99.svg)',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                position: 'relative',
                height: '400px'
            }}>
                <Row justify={'center'} align="bottom" style={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0
                }}>
                    <img alt="background" src="https://dropschema.s3.amazonaws.com/oldcouches/oldcouch_hero2.jpg"
                         style={{width: '100%', height: '100%', objectFit: 'cover', opacity: '80%'}}>
                    </img>
                    {/*<Col span={24}>*/}
                    <Row align="bottom" gutter={[20, 0]} style={{
                        position: 'absolute',
                        width: '100%',
                        color: 'white',
                        padding: '0px 0px 20px 0px'
                    }}>

                        {/*<Col xl={7} lg={7} md={22} sm={22} xs={22} offset={1}>*/}
                        {/*    <Title level={1} style={{color: '#000000', margin: '2px'}}>*/}
                        {/*        SIT ON AN</Title>*/}
                        {/*    <Title level={1} style={{color: '#000000', margin: '2px', padding: '0px 0px 0px 60px'}}>OLD*/}
                        {/*        COUCH</Title>*/}
                        {/*</Col>*/}
                    </Row>
                </Row>
            </div>
        )
    }
;
const Quotes = () => {
    return (
        <Row style={{minHeight: '300px', backgroundColor: '#FFFFFF', padding: '0px 0px 10px 0px'}} justify={'center'}
             align={'middle'}>
            {/*<Space size={'large'}>*/}
            <Col xl={14} lg={14} sm={20} xs={20} md={16}>
                <Row justify={'center'} style={{minHeight: '80px', padding: '10px 0px 10px  0px'}}>
                    <Text strong style={{color: 'rgb(197,177,88)', fontFamily: 'Louis Vuitton', fontSize: '4vw'}}>
                        REFINED BEAUTY
                    </Text>
                </Row>
                <Row>
                    <Row>
                        <Col xl={2} sm={3} md={3} lg={2} xs={3}>
                            <img alt={'quote'} style={{maxHeight: '35px'}}
                                 src={"https://dropschema.s3.amazonaws.com/oldcouches/quotes.svg"}>
                            </img>
                        </Col>
                        <Col xl={8} md={8} sm={8} style={{padding: '0px 0px 10px  0px'}}>
                            <Title level={4}>
                                We simply can't match the <b>elegance</b> of Old Couches. No one can.
                            </Title>
                            <Text Strong>
                                - Salvatore Ferragamo
                            </Text>
                        </Col>
                        <Col xl={{span: 2, offset: 4}} sm={{span: 3, offset: 2}} md={{span: 3, offset: 2}}>
                            <img alt={'quote'} style={{maxHeight: '35px'}}
                                 src={"https://dropschema.s3.amazonaws.com/oldcouches/quotes.svg"}>
                            </img>
                        </Col>
                        <Col xl={8} md={8} sm={8} lg={8}>
                            <Title level={4}>
                                You can feel it in the coffee stains and in the crusted coins under the cushions.
                                <b>You can feel history.</b>
                            </Title>
                            <Text Strong>
                                - Christian Dior
                            </Text>
                        </Col>
                    </Row>
                </Row>
            </Col>

        </Row>
    )
}

const Collection = () => {
    const isTabletOrMobile = useMediaQuery({query: '(max-width: 1224px)'})

    return (
        <>
            <Space direction={'vertical'} style={{backgroundColor: '#FFFFFF'}}>
                <Row justify={'center'} style={{backgroundColor: '#FFFFFF'}}>
                    <Col xl={16} lg={16} sm={22} xs={22} md={22}>
                        <Link to={'/catalogue?collections=Winter Collection'}
                              style={{textAlign: 'center', width: '100%'}}>
                            <Card
                                hoverable
                                style={{textAlign: 'center', width: '100%'}}
                                cover={<img style={{width: '100%'}} alt="example"
                                            src="https://dropschema.s3.amazonaws.com/oldcouches/items/makyo_designers_edition_1.jpg"/>}
                            >
                                <Meta style={{textAlign: 'center'}} title={<Text strong style={{
                                    fontFamily: 'Louis Vuitton',
                                    fontSize: isTabletOrMobile ? '4vw' : '2vw'
                                }}>
                                    NEW: WINTER COLLECTION</Text>}/>

                            </Card>
                        </Link>
                    </Col>

                </Row>
                <Row style={{backgroundColor: '#FFFFFF'}}>
                    <Col xl={{span: 10, offset: 2}} lg={{span: 10, offset: 2}}
                         sm={{span: 22, offset: 1}} md={{span: 22, offset: 1}}
                         xs={{span: 22, offset: 1}}
                    >
                        <Link to={'/catalogue?collections=Old Couches X Natoshi Shigumura'}>
                            <Card
                                hoverable
                                cover={

                                    <div>
                                        <img
                                            style={{width: '100%'}}
                                            alt="example"
                                            src={"https://dropschema.s3.amazonaws.com/oldcouches/old_couches_x_natoshi_shigumura_model_1_resize.jpg"}/>
                                        <img
                                            style={{width: '100%'}}
                                            alt="example"
                                            src={"https://dropschema.s3.amazonaws.com/oldcouches/items/goto_1.jpg"}/>
                                    </div>
                                }
                            >
                                <Meta style={{textAlign: 'center'}} title={<Text strong style={{
                                    fontFamily: 'Louis Vuitton',
                                    fontSize: isTabletOrMobile ? '4vw' : '2vw'
                                }}>
                                    OLD COUCHES X NATOSHI SHIGUMURA</Text>}/>
                            </Card>
                        </Link>
                    </Col>
                    <Col xl={{span: 10, offset: 1}} lg={{span: 10, offset: 1}}
                         sm={{span: 22, offset: 1}} md={{span: 22, offset: 1}}
                         xs={{span: 22, offset: 1}}
                    >
                        <Row>
                            <Link style={{textAlign: 'center', width: '100%'}}
                                  to={'/catalogue?collections=Designers Collection'}>
                                <Card
                                    hoverable
                                    style={{textAlign: 'center', width: '100%'}}
                                    cover={<img alt="example"
                                                style={{width: '100%'}}
                                                src={isTabletOrMobile ? "https://dropschema.s3.amazonaws.com/oldcouches/items/la_provincia_designers_edition_1.jpg"
                                                    :
                                                    "https://dropschema.s3.amazonaws.com/oldcouches/items/la_provincia_designers_edition_1.jpg"
                                                }/>}
                                >
                                    <Meta style={{textAlign: 'center'}} title={<Text strong style={{
                                        fontFamily: 'Louis Vuitton',
                                        fontSize: isTabletOrMobile ? '4vw' : '2vw'
                                    }}>
                                        DESIGNERS COLLECTION</Text>}/>
                                </Card>
                            </Link>
                            <Link style={{textAlign: 'center', width: '100%'}}
                                  to={'/catalogue?collections=Summer 2020'}>
                                <Card
                                    hoverable
                                    style={{textAlign: 'center', width: '100%'}}
                                    cover={<img style={{width: '100%'}} alt="example"
                                                src="https://dropschema.s3.amazonaws.com/oldcouches/items/brown_town_1.jpg"/>}
                                >
                                    <Meta style={{textAlign: 'center'}} title={<Text strong style={{
                                        fontFamily: 'Louis Vuitton',
                                        fontSize: isTabletOrMobile ? '4vw' : '2vw'
                                    }}>
                                        FALL 2020</Text>}/>

                                </Card>
                            </Link>
                        </Row>
                    </Col>
                </Row>
            </Space>
        </>
    )
}

const Article = () => {
    const isTabletOrMobile = useMediaQuery({query: '(max-width: 1224px)'})

    return (
        <Row justify={'center'} align={'middle'} style={{backgroundColor: '#FFFFFF', margin: '50px 0px 50px 0px'}}>
            <Col xs={23} sm={23} md={20} lg={14} xl={14}>
                <Card
                    style={{textAlign: 'center'}}
                    title={<Text strong style={{fontSize: isTabletOrMobile ? '14px' : '20px'}}>NEW FROM OUR
                        MAGAZINE</Text>}
                >
                    <MagazineListItem item={articles[0]}/>
                    <Link to={'/magazine/' + articles[0].link}>
                        <Button size='large' shape='rounded' type={'primary'}>READ NOW</Button>
                    </Link>
                </Card>
            </Col>
        </Row>
    )


}

const HomePage = () => {

    return (
        <div style={{backgroundColor: '#FFFFFF'}}>
            <Banner/>
            <Quotes/>
            <Collection/>
            <Article/>

            {/*<Row>*/}
            {/*    <Col xl={16} lg={16} sm={22} xs={22} md={20}>*/}
            {/*        <Card>*/}
            {/*        */}
            {/*        </Card>*/}
            {/*    </Col>*/}
            {/*</Row>*/}
        </div>

    )
}


export default HomePage;