import React, {useEffect, useState} from "react";
import {
    Divider,
    Row,
    Col,
    Collapse,
    InputNumber,
    Space,
    Checkbox,
    Select,
    Card,
    Statistic,
    Pagination,
    Modal, message

} from "antd";
import Text from "antd/es/typography/Text";
import {items} from './catalogueItems'
import {useMediaQuery} from "react-responsive";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import {Link} from 'react-router-dom'
import {Carousel} from 'react-responsive-carousel';
import {useLocation} from 'react-router-dom'

const {Panel} = Collapse;
const {Option} = Select;
const {Meta} = Card;

function comparer(field, ascending = true) {
    return function (a, b) {
        let comparison;
        if (a[field] > b[field]) {
            comparison = 1;
        } else {
            comparison = -1
        }
        return ascending ? comparison : -1 * comparison
    }
}

function sortItems(itemsToSort, method, ascending = true) {
    if (method === 'date') {
        return itemsToSort.slice().sort(comparer('manufacture_date', ascending)
        )
    }

    if (method === 'best_seller') {
        return itemsToSort.slice().sort(comparer('total_sold', ascending)
        )
    }
    return itemsToSort
}

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const Catalogue = ({match}) => {
    let query = useQuery();
    const {params: {productLink}} = match;
    const isTabletOrMobile = useMediaQuery({query: '(max-width: 1224px)'})
    const [minPrice, setMinPrice] = useState(10000)
    const [maxPrice, setMaxPrice] = useState(9999999)
    const [colorArray, setColorArray] = useState([])
    const [collectionArray, setCollectionArray] = useState([])
    const [ageSelect, setAgeSelect] = useState('Over 10 Years Old')
    const [modalVisible, setModalVisible] = useState(false)
    const [modalItem, setModalItem] = useState(items[0])
    const [maxDisplayItems] = useState(25)
    const [page, setPage] = useState(!!query.get('page') ? query.get('page') : 1)

    const [catalogueItems, setCatalogueItems] = useState(sortItems(items, 'best_seller', false))
    const [collectionDefaultValue] = useState(!!query.get('collections') ?
        query.get('collections').split(',') : [])

    useEffect(() => {
        if (!!query.get('collections')) {
            handeCollectionSelect(query.get('collections').split(','))
        }
    }, [])


    const handeCollectionSelect = (selectArray) => {
        setPage(1)
        setCollectionArray(selectArray)
    }

    const handleAgeSelect = (age) => {
        setPage(1)
        setAgeSelect(age)
    }


    const handleColorSelect = (selectArray) => {
        setPage(1)
        setColorArray(selectArray)
    }

    const handlePriceMinSelect = (minPrice) => {
        setPage(1)
        setMinPrice((minPrice))
    }

    const handlePriceMaxSelect = (maxPrice) => {
        setPage(1)
        setMaxPrice(maxPrice)
    }

    const filterItems = () => {

        const priceSort = items
            .filter(item => item.price > minPrice)
            .filter(item => item.price < maxPrice);

        let ageSort;

        if (ageSelect === 'Over 10 Years Old') {
            ageSort = priceSort.filter(item => {
                return (new Date().getFullYear() - item.manufacture_date.getFullYear()) > 10
            });
        } else if (ageSelect === 'Over 20 Years Old') {
            ageSort = priceSort.filter(item => {
                return (new Date().getFullYear() - item.manufacture_date.getFullYear()) > 20
            });
        } else {
            ageSort = priceSort.filter(item => {
                return (new Date().getFullYear() - item.manufacture_date.getFullYear()) > 30
            });
        }

        let colorSort = ageSort;
        if (colorArray.length > 0) {
            colorSort = ageSort.filter(item => {
                return colorArray.includes(item.color)
            });
        }

        let collectionSort = colorSort;
        if (collectionArray.length > 0) {
            collectionSort = colorSort.filter(item => {
                var exists = false
                collectionArray.forEach(selectItem => {
                    if (item.tags.includes(selectItem)) exists = true;
                })
                return exists
            });
        }

        setCatalogueItems(sortItems(collectionSort, 'best_seller', false))
    }

    useEffect(() => {
        filterItems()
    }, [ageSelect, minPrice, maxPrice, collectionArray, colorArray])


    const DisplayItems = () => {
        return (
            <>
                <Modal visible={modalVisible} okText={'Purchase'}
                    onCancel={()=>setModalVisible(false)}
                       onOk={()=>message.error('Sold Out!')}
                >
                    <Card
                    cover={<Carousel>
                        {modalItem.images.map(image=>
                            <img alt={image.split('/')[-1]} src={image} />
                        )}
                        </Carousel>}
                    >
                        <Meta style={{textAlign: 'center'}} title={<Text strong style={{
                            fontFamily: 'Louis Vuitton',
                            fontSize: isTabletOrMobile ? '2vw' : '1.4vw'
                        }}>
                            {modalItem.name}</Text>}/>

                        <Statistic style={{margin: '10px 0px 0px 0px'}}
                                   valueStyle={{fontSize: '16px'}} prefix={"$"} value={modalItem.price}
                                   precision={0}/>
                    </Card>
                </Modal>

                <Row>
                    {catalogueItems.slice((page - 1) * maxDisplayItems, (page * maxDisplayItems) - 1).map(item =>
                        <Col xl={12} lg={12} md={12} xs={24} sm={24}>
                            <a href={null} onClick={()=>{setModalVisible(true); setModalItem(item)}}>
                                <Card
                                    style={{margin: '20px', textAlign: 'center'}}
                                    cover={<img alt={item.images[0].split('/')[-1]} src={item.images[0]}/>}
                                >
                                    <Meta style={{textAlign: 'center'}} title={<Text strong style={{
                                        fontFamily: 'Louis Vuitton',
                                        fontSize: isTabletOrMobile ? '2vw' : '1.4vw'
                                    }}>
                                        {item.name}</Text>}/>

                                    <Statistic style={{margin: '10px 0px 0px 0px'}}
                                               valueStyle={{fontSize: '16px'}} prefix={"$"} value={item.price}
                                               precision={0}/>
                                </Card>
                            </a>
                        </Col>
                    )}
                </Row>
                <Row justify={'center'}>
                    <Pagination defaultCurrent={page}
                                total={catalogueItems.length}
                                pageSize={maxDisplayItems}
                                onChange={selectedPage => {
                                    window.scrollTo({top: 0, behavior: 'smooth'});
                                    setPage(selectedPage)
                                }}/>
                </Row>
            </>
        )
    }
    return (
        <>
            <Divider/>
            <Row
                justify={'center'}
            >
                <Col xl={20} lg={16} md={23} sm={23} xs={23}>
                    <Row>
                        <Col xl={6} lg={6} md={9} sm={22} xs={22}>
                            <Collapse defaultActiveKey={["collection", "color", "age", "price"]} bordered={false}>
                                <Panel header="Collection" key="collection">
                                    <Checkbox.Group
                                        defaultValue={collectionDefaultValue}
                                        onChange={handeCollectionSelect}>
                                        <Col span={24}>
                                            <Checkbox value="Fall 2020">Fall 2020</Checkbox>
                                        </Col>
                                        <Col span={24}>
                                            <Checkbox value="Winter Collection">Winter Collection</Checkbox>
                                        </Col>
                                        <Col span={24}>
                                            <Checkbox value="Old Couches X Natoshi Shigumura">Old Couches X Natoshi
                                                Shigumura</Checkbox>
                                        </Col>
                                        <Col span={24}>
                                            <Checkbox value="Designers Collection">Designers Collection</Checkbox>
                                        </Col>
                                        <Col span={24}>
                                            <Checkbox value="Il Colorante">Il Colorante</Checkbox>
                                        </Col>
                                    </Checkbox.Group>
                                </Panel>
                                <Panel header="Age" key="age">
                                    <Select defaultValue="Over 10 Years Old" style={{width: '100%'}}
                                            onChange={handleAgeSelect}>
                                        <Option value="Over 10 Years Old">Over 10 Years Old</Option>
                                        <Option value="Over 20 Years Old">Over 20 Years Old</Option>
                                        <Option value="Over 30 Years Old">Over 30 Years Old</Option>
                                    </Select>
                                </Panel>
                                <Panel header="Color" key="color">
                                    <Checkbox.Group defaultValue={['Pear']} onChange={handleColorSelect}>
                                        <Col span={24}>
                                            <Checkbox value="red">Red</Checkbox>
                                        </Col>
                                        <Col span={24}>
                                            <Checkbox value="brown">Brown</Checkbox>
                                        </Col>
                                        <Col span={24}>
                                            <Checkbox value="black">Black</Checkbox>
                                        </Col>
                                        <Col span={24}>
                                            <Checkbox value="blue">Blue</Checkbox>
                                        </Col>
                                        <Col span={24}>
                                            <Checkbox value="green">Green</Checkbox>
                                        </Col>
                                        <Col span={24}>
                                            <Checkbox value="white">White</Checkbox>
                                        </Col>
                                        <Col span={24}>
                                            <Checkbox value="indeterminate">Indeterminable</Checkbox>
                                        </Col>
                                    </Checkbox.Group>
                                </Panel>
                                <Panel header="Price" key="price">
                                    <Space>
                                        <Text>Min</Text>
                                        <InputNumber
                                            defaultValue={10000}
                                            min={10000}
                                            formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                            parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                            onChange={handlePriceMinSelect}
                                        />
                                        <Text>Max</Text>
                                        <InputNumber
                                            defaultValue={9999999}
                                            min={10000}
                                            max={99999999}
                                            formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                            parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                            onChange={handlePriceMaxSelect}
                                        />
                                    </Space>
                                </Panel>
                            </Collapse>
                        </Col>
                        <Col xl={17} lg={17} md={14} xs={22} sm={22} style={{margin: '0px 0px 0px 10px'}}>
                            <DisplayItems/>
                        </Col>
                    </Row>

                </Col>
            </Row>

        </>
    )

}


export default Catalogue;