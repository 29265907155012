export const items = [
    {
        "name": 'Äckligt',
        "images": [
            "https://dropschema.s3.amazonaws.com/oldcouches/items/ackligt_1.jpg",
            "https://dropschema.s3.amazonaws.com/oldcouches/items/ackligt_2.jpg"
        ],
        "price": 14000,
        total_sold: 100,
        "manufacture_date": new Date(2008, 10, 1),
        "color": "brown",
        "tags": ["Designers Collection", "Summer 2020"]

    },
    {
        "name": 'Amore Grande',
        "images": [
            "https://dropschema.s3.amazonaws.com/oldcouches/items/amore_grande_1.jpg",
            "https://dropschema.s3.amazonaws.com/oldcouches/items/amore_grande_2.jpg",
            "https://dropschema.s3.amazonaws.com/oldcouches/items/amore_grande_3.jpg"
        ],
        "price": 19999,
        total_sold: 104,
        "manufacture_date": new Date(2004, 10, 1),
        "color": "black",
        "tags": ["Summer 2020"]
    },

    {
        "name": 'Brown Towne',
        "images": [
            "https://dropschema.s3.amazonaws.com/oldcouches/items/brown_town_1.jpg",
            "https://dropschema.s3.amazonaws.com/oldcouches/items/brown_town_2.jpg"
        ],
        total_sold: 10,
        "price": 220000,
        "manufacture_date": new Date(1994, 10, 1),
        "color": "brown",
        "tags": ["Fall 2020"]
    },

    {
        "name": 'Brutta',
        "images": [
            "https://dropschema.s3.amazonaws.com/oldcouches/items/brutta_1.jpg"
        ],
        total_sold: 1999,
        "price": 9990000,
        "manufacture_date": new Date(2009, 10, 1),
        "color": "brown",
        "tags": ["Summer 2020", "Il Colorante", "Designers Collection"]
    },
    {
        "name": 'The Chattanooga',
        "images": [
            "https://dropschema.s3.amazonaws.com/oldcouches/items/chattanooga_1.jpg",
            "https://dropschema.s3.amazonaws.com/oldcouches/items/chattanooga_2.jpg"
        ],
        "price": 92000,
        total_sold: 14,
        "manufacture_date": new Date(2004, 10, 1),
        "color": "indeterminate",
        "tags": ["Summer 2020", "Designers Collection"]
    },
    {
        "name": 'Empress 22',
        "images": [
            "https://dropschema.s3.amazonaws.com/oldcouches/items/empress_1.jpg",
            "https://dropschema.s3.amazonaws.com/oldcouches/items/empress_2.jpg",
            "https://dropschema.s3.amazonaws.com/oldcouches/items/empress_3.jpg"
        ],
        total_sold: 101,
        "price": 12000,
        "manufacture_date": new Date(2004, 10, 1),
        "color": "indeterminate",
        "tags": ["Fall 2020"]
    },

    {
        "name": 'Enchantress',
        "images": [
            "https://dropschema.s3.amazonaws.com/oldcouches/items/enchantress_1.jpg",
            "https://dropschema.s3.amazonaws.com/oldcouches/items/enchantress_2.jpg",
        ],
        "price": 68000,
        total_sold: 1042,
        "manufacture_date": new Date(1980, 10, 1),
        "color": "brown",
        "tags": ["Designers Collection"]
    },
    {
        "name": 'Furui',
        "images": [
            "https://dropschema.s3.amazonaws.com/oldcouches/items/furui_1.jpg",
            "https://dropschema.s3.amazonaws.com/oldcouches/items/furui_2.jpg",
        ],
        total_sold: 1255,
        "price": 125000,
        "manufacture_date": new Date(1996, 10, 1),
        "color": "brown",
        "tags": ["Old Couches X Natoshi Shigumura"]
    },

    {
        "name": 'Gammal',
        "images": [
            "https://dropschema.s3.amazonaws.com/oldcouches/items/gammal_1.jpg",
            "https://dropschema.s3.amazonaws.com/oldcouches/items/gammal_2.jpg",
        ],
        "price": 1425000,
        total_sold: 999,
        "manufacture_date": new Date(1986, 10, 1),
        "color": "brown",
        "tags": ["Fall 2020"]
    },

    {
        "name": 'Goto',
        "images": [
            "https://dropschema.s3.amazonaws.com/oldcouches/items/goto_1.jpg",
            "https://dropschema.s3.amazonaws.com/oldcouches/items/goto_2.jpg",
            "https://dropschema.s3.amazonaws.com/oldcouches/items/goto_3.jpg",
        ],
        total_sold: 244,
        "price": 19000,
        "manufacture_date": new Date(2006, 10, 1),
        "color": "green",
        "tags": ["Old Couches X Natoshi Shigumura", "Fall 2020"]
    }
    ,

    {
        "name": 'Green Bandit',
        "images": [
            "https://dropschema.s3.amazonaws.com/oldcouches/items/green_bandit_1.jpg",
            "https://dropschema.s3.amazonaws.com/oldcouches/items/green_bandit_2.jpg",
        ],
        "price": 42000,
        "manufacture_date": new Date(2008, 10, 1),
        "color": "green",
        total_sold: 224,
        "tags": ["Designers Collection", "Fall 2020"]
    }
    ,
    {
        "name": 'Homesteader',
        "images": [
            "https://dropschema.s3.amazonaws.com/oldcouches/items/homesteader_1.jpg",
        ],
        total_sold: 22,
        "price": 722000,
        "manufacture_date": new Date(2001, 10, 1),
        "color": "brown",
        "tags": ["Designers Collection", "Summer 2020"]
    },

    {
        "name": 'Il Beano',
        "images": [
            "https://dropschema.s3.amazonaws.com/oldcouches/items/il_beano_1.jpg",
            "https://dropschema.s3.amazonaws.com/oldcouches/items/il_beano_2.jpg",
        ],
        "price": 424000,
        total_sold: 252,
        "manufacture_date": new Date(1983, 10, 1),
        "color": "brown",
        "tags": ["Designers Collection", "Fall 2020"]
    },
    {
        "name": 'Ghiaccio',
        "images": [
            "https://dropschema.s3.amazonaws.com/oldcouches/items/il_ghiaccio_1.jpg",
        ],
        "price": 28000,
        total_sold: 2155,
        "manufacture_date": new Date(1995, 10, 1),
        "color": "indeterminate",
        "tags": ["Il Colorante"]
    },
    {
        "name": 'Meteore',
        "images": [
            "https://dropschema.s3.amazonaws.com/oldcouches/items/il_meteore_2.jpg",
            "https://dropschema.s3.amazonaws.com/oldcouches/items/il_meteore_3.jpg",
            "https://dropschema.s3.amazonaws.com/oldcouches/items/il_meteore_4.jpg",
        ],
        "price": 72000,
        total_sold: 10,
        "manufacture_date": new Date(1999, 10, 1),
        "color": "brown",
        "tags": ["Il Colorante"]
    },

    {
        "name": 'Il Pasaporte',
        "images": [
            "https://dropschema.s3.amazonaws.com/oldcouches/items/il_pasaporte_1.jpg",
            "https://dropschema.s3.amazonaws.com/oldcouches/items/il_pasaporte_2.jpg",
        ],
        "price": 46800,
        total_sold: 500,
        "manufacture_date": new Date(2002, 10, 1),
        "color": "green",
        "tags": ["Il Colorante"]
    },

    {
        "name": 'Imperator',
        "images": [
            "https://dropschema.s3.amazonaws.com/oldcouches/items/imperator_1.jpg"
        ],
        "price": 880000,
        total_sold: 1420,
        "manufacture_date": new Date(2003, 10, 1),
        "color": "brown",
        "tags": ["Summer 2020"]
    },


    {
        "name": 'Iyana II',
        "images": [
            "https://dropschema.s3.amazonaws.com/oldcouches/items/iyana_1.jpg",
            "https://dropschema.s3.amazonaws.com/oldcouches/items/iyana_2.jpg"
        ],
        "price": 180000,
        total_sold: 140,
        "manufacture_date": new Date(2006, 10, 1),
        "color": "black",
        "tags": ["Old Couches X Natoshi Shigumura"]
    },
    {
        "name": 'Kimo I',
        "images": [
            "https://dropschema.s3.amazonaws.com/oldcouches/items/kimo_i_1.jpg",
            "https://dropschema.s3.amazonaws.com/oldcouches/items/kimo_i_1.jpg",
        ],
        "price": 5240000,
        total_sold: 102550,
        "manufacture_date": new Date(2007, 10, 1),
        "color": "red",
        "tags": ["Old Couches X Natoshi Shigumura", "Fall 2020"]
    },
    {
        "name": 'La Conochia (Desiners Edition)',
        "images": [
            "https://dropschema.s3.amazonaws.com/oldcouches/items/la_conochia_designers_edition_1.jpg",
            "https://dropschema.s3.amazonaws.com/oldcouches/items/la_conochia_designers_edition_2.jpg",
        ],
        "price": 2640000,
        total_sold: 100,
        "manufacture_date": new Date(1984, 10, 1),
        "color": "white",
        "tags": ["Designers Collection", "Winter Collection"]
    },
    {
        "name": 'La Heroine',
        "images": [
            "https://dropschema.s3.amazonaws.com/oldcouches/items/la_heroine_1.jpg",
        ],
        "price": 8240000,
        total_sold: 120,
        "manufacture_date": new Date(2001, 10, 1),
        "color": "green",
        "tags": ["Designers Collectio n"]
    },

    {
        "name": 'La Policia',
        "images": [
            "https://dropschema.s3.amazonaws.com/oldcouches/items/la_policia_1.jpg",
            "https://dropschema.s3.amazonaws.com/oldcouches/items/la_policia_2.jpg",
        ],
        "price": 140000,
        total_sold: 22,
        "manufacture_date": new Date(1994, 10, 1),
        "color": "blue",
        "tags": ["Fall 2020"]
    },
    {
        "name": 'Provincia (Designers Edition)',
        "images": [
            "https://dropschema.s3.amazonaws.com/oldcouches/items/la_provincia_designers_edition_1.jpg",
            "https://dropschema.s3.amazonaws.com/oldcouches/items/la_provincia_designers_edition_2.jpg",
        ],
        "price": 260000,
        total_sold: 245,
        "manufacture_date": new Date(1988, 10, 1),
        "color": "black",
        "tags": ["Il Colorante", "Designers Collection"]
    },
    {
        "name": 'Le Atmosfera',
        "images": [
            "https://dropschema.s3.amazonaws.com/oldcouches/items/le_atmosfera_1.jpg",
            "https://dropschema.s3.amazonaws.com/oldcouches/items/le_atmosfera_2.jpg",
            "https://dropschema.s3.amazonaws.com/oldcouches/items/le_atmosfera_3.jpg",
        ],
        "price": 65000,
        total_sold: 1255,
        "manufacture_date": new Date(1998, 10, 1),
        "color": "brown",
        "tags": ["Il Colorante"]
    },

    {
        "name": 'Makyo (Designers Edition)',
        "images": [
            "https://dropschema.s3.amazonaws.com/oldcouches/items/makyo_designers_edition_1.jpg",
        ],
        "price": 1500000,
        total_sold: 525,
        "manufacture_date": new Date(2005, 10, 1),
        "color": "red",
        "tags": ["Designers Collection", "Old Couches X Natoshi Shigumura", "Winter Collection"]
    },
    {
        "name": 'Oligarch III',
        "images": [
            "https://dropschema.s3.amazonaws.com/oldcouches/items/oligarch_1.jpg",
        ],
        total_sold: 25,
        "price": 9000000,
        "manufacture_date": new Date(2008, 10, 1),
        "color": "indeterminate",
        "tags": []
    },
    {
        "name": 'Oto',
        "images": [
            "https://dropschema.s3.amazonaws.com/oldcouches/items/oto_1.jpg",
            "https://dropschema.s3.amazonaws.com/oldcouches/items/oto_2.jpg",
            "https://dropschema.s3.amazonaws.com/oldcouches/items/oto_3.jpg",
        ],
        "price": 1700000,
        total_sold: 1255,
        "manufacture_date": new Date(2006, 10, 1),
        "color": "brown",
        "tags": ["Old Couches X Natoshi Shigumura", "Fall 2020"]
    },
    {
        "name": 'Piccolo Rigazzo',
        "images": [
            "https://dropschema.s3.amazonaws.com/oldcouches/items/piccolo_rigazzo_1.jpg",
        ],
        "price": 70000,
        total_sold: 244,
        "manufacture_date": new Date(2004, 10, 1),
        "color": "brown",
        "tags": ["Il Colorante", "Fall 2020"]
    },
    {
        "name": 'Reclinado',
        "images": [
            "https://dropschema.s3.amazonaws.com/oldcouches/items/reclinado_1.jpg",
            "https://dropschema.s3.amazonaws.com/oldcouches/items/reclinado_2.jpg",
        ],
        "price": 19000,
        total_sold: 990,
        "manufacture_date": new Date(2005, 10, 1),
        "color": "white",
        "tags": ["Il Colorante", "Summer 2020"]
    },

    {
        "name": 'Regalio Cantos',
        "images": [
            "https://dropschema.s3.amazonaws.com/oldcouches/items/regalia_cantos_1.jpg",
            "https://dropschema.s3.amazonaws.com/oldcouches/items/regalia_cantos_2.jpg",
        ],
        "price": 29000,
        total_sold: 100,
        "manufacture_date": new Date(1985, 10, 1),
        "color": "white",
        "tags": ["Il Colorante", "Summer 2020"]
    },
    {
        "name": 'The Reginald',
        "images": [
            "https://dropschema.s3.amazonaws.com/oldcouches/items/reginald_1.jpg",
            "https://dropschema.s3.amazonaws.com/oldcouches/items/reginald_2.jpg",
        ],
        "price": 42000,
        total_sold: 45,
        "manufacture_date": new Date(1988, 10, 1),
        "color": "indeterminate",
        "tags": []
    },
    {
        "name": 'Rubia Ignacio',
        "images": [
            "https://dropschema.s3.amazonaws.com/oldcouches/items/rubia_ignacio_1.jpg",
            "https://dropschema.s3.amazonaws.com/oldcouches/items/rubia_ignacio_2.jpg",
        ],
        "price": 82000,
        total_sold: 12,
        "manufacture_date": new Date(1998, 10, 1),
        "color": "red",
        "tags": ["Winter Collection"]
    },
    {
        "name": 'The Senshoku',
        "images": [
            "https://dropschema.s3.amazonaws.com/oldcouches/items/senshoku_1.jpg",
            "https://dropschema.s3.amazonaws.com/oldcouches/items/senshoku_2.jpg",
            "https://dropschema.s3.amazonaws.com/oldcouches/items/senshoku_3.jpg",
        ],
        "price": 192000,
        total_sold: 244,
        "manufacture_date": new Date(1994, 10, 1),
        "color": "white",
        "tags": ["Old Couches X Natoshi Shigumura"]
    },
    {
        "name": 'Smutsig',
        "images": [
            "https://dropschema.s3.amazonaws.com/oldcouches/items/smutsig_1.jpg",
        ],
        "price": 12000,
        total_sold: 240,
        "manufacture_date": new Date(1991, 10, 1),
        "color": "red",
        "tags": ["Winter Collection"]
    },
    {
        "name": 'Sporca Y Blanco',
        "images": [
            "https://dropschema.s3.amazonaws.com/oldcouches/items/sporca_y_blanco_1.jpg",
            "https://dropschema.s3.amazonaws.com/oldcouches/items/sporca_y_blanco_2.jpg",
        ],
        "price": 62000,
        total_sold: 1224,
        "manufacture_date": new Date(1993, 10, 1),
        "color": "white",
        "tags": []
    },
    {
        "name": 'The Sheikh',
        "images": [
            "https://dropschema.s3.amazonaws.com/oldcouches/items/the_sheikh_1.jpg",
        ],
        "price": 162000,
        total_sold: 1255,
        "manufacture_date": new Date(1998, 10, 1),
        "color": "brown",
        "tags": []
    },
    {
        "name": 'The Shibuya',
        "images": [
            "https://dropschema.s3.amazonaws.com/oldcouches/items/the_shibuya_1.jpg",
            "https://dropschema.s3.amazonaws.com/oldcouches/items/the_shibuya_2.jpg",
            "https://dropschema.s3.amazonaws.com/oldcouches/items/the_shibuya_3.jpg",
        ],
        "price": 1262000,
        total_sold: 1255,
        "manufacture_date": new Date(1988, 10, 1),
        "color": "indeterminate",
        "tags": ["Old Couches X Natoshi Shigumura"]
    },
    {
        "name": 'Vento Virtellio',
        "images": [
            "https://dropschema.s3.amazonaws.com/oldcouches/items/vento_rosa_1.jpg",
            "https://dropschema.s3.amazonaws.com/oldcouches/items/vento_rosa_2.jpg",
        ],
        "price": 26000,
        total_sold: 1552,
        "manufacture_date": new Date(1988, 10, 1),
        "color": "brown",
        "tags": []
    },

    {
        "name": 'Yama',
        "images": [
            "https://dropschema.s3.amazonaws.com/oldcouches/items/yama_1.jpg",
            "https://dropschema.s3.amazonaws.com/oldcouches/items/yama_2.jpg",
            "https://dropschema.s3.amazonaws.com/oldcouches/items/yama_3.jpg",
        ],
        "price": 122000,
        total_sold: 2155,
        "manufacture_date": new Date(2009, 10, 1),
        "color": "brown",
        "tags": ["Old Couches X Natoshi Shigumura"]
    }
]