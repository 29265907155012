import React from 'react';
import Navigation from "./Navigation";
import MagazinePage, {ArticlePage} from "./Magazine";
import HomePage from "./Home";
import Catalogue from './Catalogue'
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {Layout, Typography} from "antd";
import Footer from 'rc-footer';
import 'rc-footer/assets/index.css';
import ScrollToTop from "./ScrollToTop";
import * as ROUTES from '../contants/routes'

const {Text} = Typography
const {Content} = Layout;

const HEADER_STYLE_NOT_FIXED = {
    zIndex: 1, width: '100%', lineHeight: '62px',
    // position: 'absolute',
    padding: '0px 10px 0px 10px',
    top: '0',
    left: '0',
    right: '0',
    backgroundColor: '#FFFFFF'
}

function App() {
    return (
        <Router>
            <ScrollToTop/>
            <Layout>
                <Switch>

                    <Route exact path={'/'}>
                        <Navigation header_style={HEADER_STYLE_NOT_FIXED}/>
                        <Route exact path={ROUTES.HOME} component={HomePage}/>
                    </Route>
                    <Route exact path={['/*']}>
                        <Navigation header_style={HEADER_STYLE_NOT_FIXED}/>
                        <Content className="site-layout"
                                 style={{padding: '5px 20px 0px 20px', marginTop: 1, zIndex: 0,
                                 backgroundColor: '#FFFFFF'}}>

                            <Route exact path={ROUTES.MAGAZINE} component={MagazinePage}/>
                            <Route exact path={ROUTES.MAGAZINE + '/:articleLink'} component={ArticlePage}/>
                            <Route exact path={ROUTES.CATALOGUE} component={Catalogue}/>
                            <Route exact path={ROUTES.CATALOGUE + '/:productLink'} component={Catalogue}/>
                        </Content>
                    </Route>
                </Switch>
                <Footer
                    theme={'light'}
                    backgroundColor={'#FFFFFF'}
                    columns={[
                        {
                            icon: (
                                <img alt='support-symbol'
                                     src="https://gw.alipayobjects.com/zos/rmsportal/XuVpGqBFxXplzvLjJBZB.svg"/>
                            ),
                            title: 'Contact Us',
                            openExternal: true
                        },
                        {
                            title: 'Careers',
                            openExternal: true
                        },
                        {
                            title: 'Sitemap',
                            openExternal: true
                        },
                        {
                            title: 'Legal Notice',
                            openExternal: true
                        },
                        {
                            title: 'Apps',
                            openExternal: true
                        },
                    ]}
                    bottom={
                        <Text strong style={{fontFamily: 'Louis Vuitton', fontSize: '18px'}}>Old Couches</Text>}
                />
            </Layout>
        </Router>
    )
}

export default App;
